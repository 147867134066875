// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../Heading/H2.res.js";
import * as React from "react";
import * as GoogleMap from "../../../bindings/GoogleMap.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as GoogleMapStyles from "../../styles/GoogleMapStyles.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as GoogleMapWithBounds from "../GoogleMap/GoogleMapWithBounds.res.js";
import * as Global from "Client/common/global";
import * as Api from "@react-google-maps/api";
import * as BannerGoogleMapsScss from "./BannerGoogleMaps.scss";
import * as BannerGoogleMapsInfoBox from "./BannerGoogleMapsInfoBox.res.js";

var css = BannerGoogleMapsScss;

function BannerGoogleMaps(props) {
  var color = props.color;
  var allLocationsJson = props.allLocationsJson;
  var match = React.useReducer((function (state, action) {
          if (typeof action !== "object") {
            return {
                    showInfoBox: false,
                    infoBoxLocation: state.infoBoxLocation
                  };
          } else {
            return {
                    showInfoBox: true,
                    infoBoxLocation: action._0
                  };
          }
        }), {
        showInfoBox: false,
        infoBoxLocation: undefined
      });
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.showInfoBox;
  var match$2 = state.infoBoxLocation;
  var tmp;
  switch (color) {
    case "Orange" :
        tmp = GoogleMap.MarkerClusterer.Style.orange;
        break;
    case "Red" :
        tmp = GoogleMap.MarkerClusterer.Style.red;
        break;
    case "Default" :
        tmp = GoogleMap.MarkerClusterer.Style.$$default;
        break;
    
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H2.make, {
                              children: props.title
                            }),
                        JsxRuntime.jsx("p", {
                              children: props.description
                            })
                      ],
                      className: css.heading
                    }),
                JsxRuntime.jsx(Api.LoadScript, {
                      googleMapsApiKey: Global.GoogleMapAPI,
                      libraries: Global.GoogleMapLibraries,
                      children: JsxRuntime.jsxs(GoogleMapWithBounds.make, {
                            mapContainerClassName: css.mapElement,
                            style: GoogleMapStyles.standard,
                            options: {
                              mapTypeControl: false,
                              zoomControl: true,
                              zoomControlOptions: {
                                position: 5
                              },
                              streetViewControl: false,
                              fullscreenControl: false
                            },
                            bounds: Belt_Array.map(allLocationsJson, (function ($$location) {
                                    return {
                                            lat: $$location.lat,
                                            lng: $$location.lng
                                          };
                                  })),
                            children: [
                              match$1 && match$2 !== undefined ? JsxRuntime.jsx(GoogleMap.InfoBox.make, {
                                      position: {
                                        lat: match$2.lat,
                                        lng: match$2.lng
                                      },
                                      contentWidth: 240.0,
                                      contentHeight: 250.0,
                                      children: JsxRuntime.jsx(BannerGoogleMapsInfoBox.make, {
                                            providerType: match$2.type,
                                            location: match$2.name,
                                            onClose: (function (param) {
                                                dispatch("CloseInfoBox");
                                              })
                                          })
                                    }) : null,
                              JsxRuntime.jsx(GoogleMap.MarkerClusterer.make, {
                                    averageCenter: true,
                                    enableRetinaIcons: true,
                                    gridSize: 60,
                                    minimumClusterSize: 3,
                                    styles: tmp,
                                    children: (function (clusterer) {
                                        return Belt_Array.map(allLocationsJson, (function ($$location) {
                                                      var tmp;
                                                      switch (color) {
                                                        case "Orange" :
                                                            tmp = GoogleMap.Marker.Icon.orange;
                                                            break;
                                                        case "Red" :
                                                            tmp = GoogleMap.Marker.Icon.red;
                                                            break;
                                                        case "Default" :
                                                            tmp = GoogleMap.Marker.Icon.$$default;
                                                            break;
                                                        
                                                      }
                                                      return JsxRuntime.jsx(GoogleMap.Marker.make, {
                                                                  position: {
                                                                    lat: $$location.lat,
                                                                    lng: $$location.lng
                                                                  },
                                                                  onClick: (function () {
                                                                      dispatch({
                                                                            TAG: "OpenInfoBox",
                                                                            _0: $$location
                                                                          });
                                                                    }),
                                                                  icon: tmp,
                                                                  clusterer: Caml_option.some(clusterer)
                                                                }, $$location.name);
                                                    }));
                                      })
                                  })
                            ]
                          })
                    })
              ],
              className: css.wrapper
            });
}

var make = BannerGoogleMaps;

export {
  css ,
  make ,
}
/* css Not a pure module */
